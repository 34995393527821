import React, { useState, useEffect } from "react"
import ItemsCarousel from "react-items-carousel"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"
import { makeStyles, Container, Typography, Link } from "@material-ui/core"

export default function ProductBlock({
  products,
  listingTitle,
  listingSubtitle,
  isPreview,
}) {
  const styles = useStyles()
  const [numberOfCards, setNumberOfCards] = useState(4)
  const [activeItemIndex, setActiveItemIndex] = useState(0)
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" && window.innerWidth
  )

  useEffect(() => {
    function listenToResize() {
      if (typeof window !== "undefined") {
        setWindowWidth(window.innerWidth)
      }
    }
    window.addEventListener("resize", listenToResize)
    return () => {
      window.removeEventListener("resize", listenToResize)
    }
  }, [])

  function changeActiveItem(activeItem) {
    setActiveItemIndex(activeItem)
  }

  function getNumberOfCard() {
    if (windowWidth < 900) {
      return 2
    } else if (windowWidth < 1300) {
      return 3
    }
    return 4
  }
  return (
    windowWidth && (
      <Container maxWidth="md" className={styles.productBlockContainer}>
        <div className={styles.productBlockContent}>
          <Typography variant="h2" className={styles.subtitle}>
            {listingTitle}
          </Typography>
          {listingSubtitle && (
            <div className={styles.body1}>{listingSubtitle}</div>
          )}
          <div className={styles.productsWrapper}>
            {windowWidth < 769 ? (
              <div className={styles.productItems}>
                {products.map((product, index) => (
                  <div className={styles.productItem} key={index}>
                    <Link
                      variant="body2"
                      underline="none"
                      className={styles.link}
                      href={product.productLink}
                    >
                      {product.productName}
                      <div className={styles.image}>
                        <ImgWithLazyload
                          isPreview={isPreview}
                          src={product.productPicture}
                          alt={product.productAltPicture}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <ItemsCarousel
                // Carousel configurations
                numberOfCards={getNumberOfCard()}
                gutter={12}
                showSlither={false}
                firstAndLastGutter={false}
                freeScrolling={false}
                // Active item configurations
                requestToChangeActive={changeActiveItem}
                activeItemIndex={activeItemIndex}
                activePosition={"center"}
                chevronWidth={48}
                rightChevron={<ChevronRightIcon />}
                leftChevron={<ChevronLeftIcon />}
                outsideChevron={true}
              >
                {products.map((product, index) => (
                  <div className={styles.productItem} key={index}>
                    <Link
                      variant="body2"
                      underline="none"
                      className={styles.link}
                      href={product.productLink}
                    >
                      {product.productName}
                      <div className={styles.image}>
                        <ImgWithLazyload
                          isPreview={isPreview}
                          src={product.productPicture}
                          alt={product.productAltPicture}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
              </ItemsCarousel>
            )}
          </div>
        </div>
      </Container>
    )
  )
}

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.text.primary,
  },
  productBlockContent: {
    maxWidth: "90%",
    margin: "0 auto",
    textAlign: "center",

    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
    "&::after": {
      display: "block",
      content: "",
      clear: "both",
    },
  },

  productsWrapper: {
    margin: "48px auto",

    [theme.breakpoints.down("xs")]: {
      marginTop: 32,
      overflowX: "auto",
    },

    "& svg": {
      fontSize: 30,
      color: "black",
    },
  },

  productItems: {
    display: "flex",
    width: "fit-content",

    [theme.breakpoints.down("xs")]: {
      marginLeft: 24,
    },
  },

  productItem: {
    height: 268,
    width: 248,
    background: "#F6F6F6",
    borderRadius: 16,
    padding: "24px 24px 0",
    boxSizing: "border-box",
    margin: "auto",

    "& a": {
      height: "100%",
      display: "grid",
    },

    [theme.breakpoints.down("xs")]: {
      marginRight: 24,
      width: 218,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  image: {
    alignItems: "flex-end",
    alignSelf: "flex-end",
    "& img": {
      objectFit: "scale-down",
      verticalAlign: "bottom",
      maxHeight: 150,
    },
  },
}))
