import * as React from "react"
import {
  Select,
  OutlinedInput,
  FormControl,
  makeStyles,
  CircularProgress,
  MenuItem,
} from "@material-ui/core"

export const RoundedSelect = ({ children, placeholder, height, ...props }) => {
  const classes = useStyle({ height })
  const placeholderItem = (
    <MenuItem disabled key={placeholder} value={0}>
      {placeholder}
    </MenuItem>
  )
  const childrens = React.useMemo(
    () =>
      Array.isArray(children)
        ? [placeholderItem, ...children]
        : [
            placeholderItem,
            children || (
              <MenuItem disabled value="loading">
                <CircularProgress color="secondary" size={16} />
              </MenuItem>
            ),
          ],
    [children, placeholderItem]
  )

  return (
    <FormControl fullWidth>
      <Select
        defaultValue={0}
        classes={{ root: classes.select, focused: classes.selectFocused }}
        {...props}
        MenuProps={{
          className: classes.popover,
          anchorOrigin: {
            vertical: 50,
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        input={
          <OutlinedInput
            classes={{ root: classes.root, focused: classes.focused }}
          />
        }
      >
        {childrens.map((child, index) =>
          child
            ? React.cloneElement(child, {
                ...child.props,
                key: index,
                className:
                  child.props.value === "loading"
                    ? classes.menuListItemLoading
                    : classes.menuListItem,
              })
            : child
        )}
      </Select>
    </FormControl>
  )
}

const useStyle = makeStyles(theme => ({
  popover: {
    "& > .MuiPaper-root": {
      borderRadius: 16,
      boxShadow: "0px 0px 34px rgba(0, 0, 0, 0.1)",
    },
  },
  menuListItem: {
    padding: theme.spacing(2),
    "&:hover": {
      backgroundColor: "#F6F6F6",
    },
  },
  menuListItemLoading: {
    padding: theme.spacing(6),
    display: "flex",
    justifyContent: "center",
  },
  select: {
    "&.MuiSelect-select:focus": {
      borderRadius: 42,
    },
    display: "flex",
    alignItems: "center",
    height: 32,
    padding: "5px 17px",
    width: "100%",
    paddingRight: "34px !important",
  },
  root: {
    height: ({ height }) => height || 42,
    borderRadius: 42,
    color: "#787878",
    fontSize: 16,
    "& > input": {
      "&::placeholder": {
        color: "#787878",
        opacity: 1,
      },
    },
    "& > fieldset": {
      borderColor: "#DBDFE8",
    },
    "&:hover": {
      backgroundColor: "#F6F6F6",
      "& > fieldset": {
        borderColor: "#DBDFE8 !important",
      },
    },
    "& > .MuiSelect-icon": {
      color: "#A4E7DB",
      right: 10,
    },
  },
  focused: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
    "& > fieldset": {
      border: "solid 1px #DBDFE8 !important",
    },
  },
}))
