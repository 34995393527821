import { useTheme } from "@material-ui/core"

export default function useColor(color) {
  const theme = useTheme()
  const chartColor = {
    Yellow: theme.palette.yellow,
    Primary: theme.palette.primary.main,
    Pink: theme.palette.primary.main,
    Secondary: theme.palette.secondary.main,
    Green: theme.palette.secondary.main,
    Grey: "#F6F6F6",
    Black: theme.palette.text.primary,
    White: theme.palette.common.white,
  }

  if (!color || !(color in chartColor)) {
    return "inherit"
  }

  return chartColor[color]
}
