import React from "react"
import { makeStyles, Typography, Button, Container } from "@material-ui/core"
import useColor from "src/hooks/useColor"

export default function USPBackgroundImage({
  image,
  title,
  text,
  buttonText,
  buttonLink,
  imageAlt,
  isPreview,
  backgroundColor,
  secondBackgroundColor,
  mobileImage,
}) {
  const topColor = useColor(backgroundColor)
  const bottomColor = useColor(secondBackgroundColor)
  const styles = useStyle({
    backgroundImage: image,
    topColor,
    bottomColor,
    mobileImage,
  })
  return (
    <div className={styles.bloc}>
      <Container className={styles.container} maxWidth="md">
        <div className={styles.imageContainer} />
        <div className={styles.textContainer}>
          <Typography variant="h2" className={styles.subtitle}>
            {title}
          </Typography>
          <Typography className={styles.body1}>{text}</Typography>
          {buttonText && (
            <Button
              variant="contained"
              color="primary"
              className={styles.blueButton}
              href={buttonLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              {buttonText}
            </Button>
          )}
        </div>
      </Container>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  bloc: {
    background: ({ topColor, bottomColor }) =>
      `linear-gradient(${
        topColor === "inherit" ? "transparent" : topColor
      } 50%, ${bottomColor === "inherit" ? "transparent" : bottomColor} 50%)`,
  },
  container: {
    position: "relative",
    padding: 0,
    overflow: "hidden",
  },
  textContainer: {
    color: "white",
    position: "absolute",
    zIndex: 2,
    left: 100,
    bottom: 50,
    right: "30%",
    maxWidth: "75%",
    [theme.breakpoints.down("sm")]: {
      right: theme.spacing(5),
      bottom: theme.spacing(3),
      left: theme.spacing(5),
      maxWidth: "unset",
    },
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    fontSize: 44,
    fontWeight: 700,
    [theme.breakpoints.down("sm")]: {
      fontSize: 26,
    },
  },
  body1: {
    marginBottom: theme.spacing(3),
  },
  imageContainer: {
    width: "calc(100% - 32px)",
    height: 400,
    margin: "auto",
    objectFit: "cover",
    background: ({ backgroundImage }) =>
      `no-repeat center url('${backgroundImage}')`,
    backgroundSize: ({}) => "cover",
    [theme.breakpoints.down("xs")]: {
      background: ({ backgroundImage, mobileImage }) =>
        `no-repeat center url('${mobileImage || backgroundImage}')`,
      backgroundSize: ({}) => "cover",
    },
  },
}))
