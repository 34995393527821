import React from "react"
import { makeStyles, Typography, Button } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser"

import ZeroRiskPackageTooltip from "./ZeroRiskPackageTooltip"

export default function MurfyOffersRecondition({
  title,
  subtitle,
  DIYButton,
  DIYSubtitle,
  DIYTitle,
  DIYContent,
  RepairContent,
  ReconditionContent,
  ReconditionButton,
  ReconditionSubtitle,
  ReconditionTitle,
  RepairButton,
  RepairSubtitle,
  RepairTooltipContent,
  RepairTitle,
}) {
  const styles = useStyle()
  return (
    <div className={styles.murfyPriceHomepageContainer}>
      <div className={styles.titleContainer}>
        {title && (
          <Typography variant="h2" align="center">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography variant="body2" align="center">
            {subtitle}
          </Typography>
        )}
      </div>
      <div className={styles.murfyLandingPageSubcontainer}>
        <div className={styles.murfyPriceHomepageContainerWrap}>
          <div
            className={`${styles.murfyPriceHomePageFreeRecondition} ${styles.murfyPriceHomePageBox}`}
          >
            <Typography color="inherit" variant="h3">
              {DIYTitle}
            </Typography>
            <Typography variant="caption" color="inherit">
              {DIYSubtitle}
            </Typography>
            <Typography className={styles.murfyCtaPrice}>0 €</Typography>
            {DIYContent &&
              DIYContent.map((content, index) => (
                <div className={styles.row} key={index}>
                  <CheckIcon className={styles.iconDiy} />
                  <Typography variant="body2">{content.row}</Typography>
                </div>
              ))}
            <Button
              variant="outlined"
              href="/product-selection"
              color="secondary"
              className={styles.diyButton}
            >
              {DIYButton}
            </Button>
          </div>
          <div
            className={` ${styles.murfyPriceHomePageExpertRecondition} ${styles.murfyPriceHomePageBox}`}
          >
            <Typography
              variant="h3"
              className={` ${styles.murfyCtaTitle} ${styles.murfyCtaTitleRepair} `}
            >
              {RepairTitle}
            </Typography>
            <Typography
              variant="caption"
              color="inherit"
              className={styles.caption}
            >
              {RepairSubtitle}
            </Typography>
            <Typography className={styles.murfyCtaPrice}>85 €</Typography>

            {RepairContent &&
              RepairContent.map((content, index) => (
                <div className={styles.row} key={index}>
                  <CheckIcon className={styles.iconDiy} />
                  <Typography variant="body2"> {content.row}</Typography>
                </div>
              ))}

            <div className={styles.row}>
              <VerifiedUserIcon className={styles.iconDiy} />
              <Typography variant="body2">
                Pack "Zéro Risque" inclus &nbsp;
              </Typography>
              <span className={styles.toolTipButton}>
                <ZeroRiskPackageTooltip TooltipContent={RepairTooltipContent} />
              </span>
            </div>
            <Typography variant="caption" color="inherit">
              Récupérez vos 85€ en avoirs si les pièces détachées sont trop
              chères
            </Typography>
            <Button
              variant="contained"
              color="primary"
              id="demander-une-intervention"
              href="/rendez-vous-reparateur/choix-de-creneau"
              className={styles.diyButton}
            >
              {RepairButton}
            </Button>
          </div>
          <div
            className={` ${styles.murfyPriceHomePageShop} ${styles.murfyPriceHomePageBox}`}
          >
            <Typography variant="h3" className={styles.murfyCtaTitle}>
              {ReconditionTitle}
            </Typography>
            <Typography
              variant="caption"
              color="inherit"
              className={styles.caption}
            >
              {ReconditionSubtitle}
            </Typography>
            <Typography className={styles.murfyCtaPrice}>-40 %</Typography>
            {ReconditionContent &&
              ReconditionContent.map((content, index) => (
                <div className={styles.row} key={index}>
                  <CheckIcon className={styles.iconDiy} />
                  <Typography variant="body2"> {content.row}</Typography>
                </div>
              ))}
            <Button
              variant="outlined"
              color="secondary"
              href="/reconditionne?utm_source=murfy.fr&utm_medium=referal"
              className={styles.diyButton}
            >
              {ReconditionButton}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  murfyPriceHomepageContainer: {
    maxWidth: 1300,
    margin: "auto",
  },
  murfyLandingPageSubcontainer: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  murfyCtaPrice: {
    fontSize: 44,
    fontWeight: 900,
  },
  murfyPriceHomepageContainerWrap: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      alignItems: "stretch",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      justifyContent: "flex-start",
    },
  },
  titleContainer: {
    width: "70%",
    margin: "auto",
    marginBottom: theme.spacing(4),
    display: "grid",
    gridTemplateColumn: "1fr",
    gridRowGap: theme.spacing(),
  },
  toolTipButton: {
    cursor: "pointer",
  },
  murfyPriceHomePageBox: {
    width: "32%",
    borderRadius: 4,
    padding: theme.spacing(6),
    display: "grid",
    gridTemplateColumn: "1fr",
    gridRowGap: theme.spacing(),
    height: 420,
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.08), 1px 1px 15px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      height: "unset",
      minWidth: "40vw",
      width: "40vw",
      marginRight: theme.spacing(2),
      boxShadow: "none",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "80vw",
      padding: theme.spacing(3),
      width: "80vw",
      height: "unset",
    },
  },
  murfyPriceHomePageFreeRecondition: {
    backgroundColor: "white",
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      order: 2,
      marginRight: theme.spacing(2),
      border: "1px solid rgba(0, 0, 0, 0.05)",
    },
  },
  murfyPriceHomePageExpertRecondition: {
    backgroundColor: theme.palette.secondary.main,
    width: "33%",
    height: 480,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      order: -1,
      minWidth: "40vw",
      width: "40vw",
      height: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "80vw",
      width: "80vw",
      height: "unset",
    },
  },
  diyButton: {
    margin: "auto",
    marginTop: theme.spacing(4),
    color: theme.palette.secondary.main,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  murfyPriceHomePageShop: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    [theme.breakpoints.down("sm")]: {
      order: 0,
    },
  },
  iconDiy: {
    marginRight: theme.spacing(),
  },
}))
