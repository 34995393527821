import * as React from "react"
import {
  MenuItem,
  Grid,
  Container,
  makeStyles,
  Button,
  ButtonBase,
  Divider,
  Box,
  Drawer,
  IconButton,
  useTheme,
  Typography,
  Hidden,
} from "@material-ui/core"
import clsx from "clsx"
import { RoundedSelect } from "components/roundedSelect/RoundedSelect"
import { RoundedInput } from "components/roundedInput/RoundedInput"
import PlaceIcon from "@material-ui/icons/Place"
import { GoogleAutoComplete } from "components/googleAutoComplete/GoogleAutoComplete"
import BuildIcon from "@material-ui/icons/Build"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { repairStorage } from "../../helpers/repairStorage"
import IconGuarantee from "./assets/iconGuarantee.svg"
import IconLater from "./assets/iconLater.svg"

const DRAWER_PRODUCT = "DRAWER_PRODUCT"
const DRAWER_ADDRESS = "DRAWER_ADDRESS"

export function RepairSearch({
  products,
  searchTitle,
  background_image,
  color,
  title,
  variant,
  subtitle,
}) {
  const theme = useTheme()
  const classes = useStyle({
    variant,
    backgroundImage: background_image,
    color,
  })
  const [isSticky, setIsSticky] = React.useState(false)
  const ref = React.useRef(null)
  const [drawer, setDrawer] = React.useState(null)
  const scrollListener = React.useCallback(() => {
    if (window.scrollY >= ref.current.offsetTop) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }, [ref])

  React.useEffect(() => {
    repairStorage.remove()
  }, [])

  React.useEffect(() => {
    if (ref.current) {
      document.addEventListener("scroll", scrollListener)
    }
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [ref, scrollListener])

  const handleOpenSlider = React.useCallback(() => {
    setDrawer(DRAWER_PRODUCT)
  }, [])

  const handleSelect = React.useCallback(
    ({ target: { value } }) => {
      const product = products.find(product => product._id === value)
      if (!product) {
        return
      }
      repairStorage.setProduct(product)
    },
    [products]
  )
  const handleSelectProduct = React.useCallback(
    product => () => {
      handleSelect({ target: { value: product._id } })
      setDrawer(DRAWER_ADDRESS)
    },
    [handleSelect]
  )

  const handleChangeAddress = React.useCallback(address => {
    repairStorage.setAddress(address)
    if (typeof window !== "undefined") {
      window.location.href = "/rendez-vous-reparateur/choix-de-creneau"
    }
  }, [])

  const handleReturn = React.useCallback(() => {
    setDrawer(prevState => {
      if (prevState === DRAWER_ADDRESS) {
        return DRAWER_PRODUCT
      }
      if (prevState === DRAWER_PRODUCT) {
        return null
      }
    })
  }, [])

  const splitTitle = title.split(/<|>/).filter(i => !i.includes("span"))
  return (
    <div className={classes.container}>
      <div className={classes.svgContainer}>
        <svg
          className={classes.svg}
          version="1.1"
          id="background-shape"
          x="0px"
          y="0px"
          viewBox="0 0 1014.9 657"
          height="100%"
          preserveAspectRatio="xMinYMin slice"
          fill="currentColor"
        >
          <path d="M910,279A729.39,729.39,0,0,1,965,0H0V657H1014.9A729.3,729.3,0,0,1,910,279Z" />
        </svg>
      </div>
      {variant && (
        <Box display="flex" mt={5} alignItems="center">
          <Container maxWidth="md">
            <div maxWidth="md" style={{ position: "relative" }}>
              <Typography
                component="h1"
                className={classes.titleTextVariant}
                color="secondary"
              >
                {splitTitle[0]}
                {splitTitle.length > 0 && (
                  <span style={{ color: "white" }}>{splitTitle[1]}</span>
                )}
                {splitTitle.length > 1 && splitTitle[2]}
              </Typography>
              <Typography color="secondary" component="h2">
                {subtitle}
              </Typography>
            </div>
          </Container>
        </Box>
      )}
      <div ref={ref}>
        <Hidden xsDown implementation="css">
          <div className={isSticky ? classes.isSticky : undefined}>
            <div className={classes.desktopContainer}>
              <Grid container className={classes.whiteBackground}>
                <Grid item>
                  <Box pr={1}>
                    <RoundedSelect
                      fullWidth
                      placeholder="Appareil à réparer"
                      onChange={handleSelect}
                    >
                      {products &&
                        products.map(product => (
                          <MenuItem key={product._id} value={product._id}>
                            {product.name}
                          </MenuItem>
                        ))}
                    </RoundedSelect>
                  </Box>
                </Grid>
                <Grid item className={classes.flex}>
                  <Box pr={1} pl={1}>
                    <GoogleAutoComplete
                      onChange={handleChangeAddress}
                      id="google-automplete-mobile"
                    >
                      <RoundedInput
                        fullWidth
                        height={42}
                        placeholder="Saisissez votre adresse"
                        InputProps={{
                          endAdornment: (
                            <PlaceIcon style={{ color: "#A4E7DB" }} />
                          ),
                        }}
                      />
                    </GoogleAutoComplete>
                  </Box>
                </Grid>
                <Grid item>
                  <Box pl={1}>
                    <Button
                      href="/rendez-vous-reparateur/choix-de-creneau"
                      color="secondary"
                      variant="contained"
                    >
                      {searchTitle || "Prendre rendez-vous"}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {!isSticky && variant && (
                <Box mb={19} mt={2} mr={1} ml={1}>
                  <Grid container className={classes.iconContainer}>
                    <Grid item xs={4} className={classes.iconVariant}>
                      <IconGuarantee />{" "}
                      <Typography>Réparation garantie 6 mois</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.iconVariant}>
                      <IconLater /> <Typography>Déplacements inclus</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.iconVariant}>
                      <IconGuarantee />{" "}
                      <Typography>Pack zéro risque</Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </div>
          </div>
        </Hidden>
        <Hidden smUp implementation="css">
          <Grid
            container
            className={clsx(
              { [classes.isSticky]: isSticky },
              classes.mobileContainer
            )}
          >
            <Grid item className={classes.mobileProduct}>
              <ButtonBase
                className={classes.productButton}
                onClick={handleOpenSlider}
              >
                Quel appareil est en panne ?
                <span className={classes.productIconContainer}>
                  <BuildIcon />
                </span>
              </ButtonBase>
              <Drawer
                anchor="right"
                style={{ zIndex: "999" }}
                PaperProps={{
                  className: classes.drawer,
                }}
                open={Boolean(drawer)}
              >
                <div className={classes.drawerContainer}>
                  <div className={classes.drawerItem}>
                    <IconButton size="small" onClick={handleReturn}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    {drawer === DRAWER_ADDRESS && (
                      <GoogleAutoComplete
                        onChange={handleChangeAddress}
                        id="mobile-autoComplete"
                      >
                        <RoundedInput
                          fullWidth
                          flat
                          placeholder="Saisissez votre adresse"
                        />
                      </GoogleAutoComplete>
                    )}
                    {drawer === DRAWER_PRODUCT && (
                      <div className={classes.placeholder}>
                        Sélectionnez l’appareil en panne
                      </div>
                    )}
                  </div>
                  <Divider />
                  {drawer === DRAWER_PRODUCT && (
                    <div className={classes.scrollable}>
                      {products.map(product => (
                        <MenuItem
                          key={product.id}
                          onClick={handleSelectProduct(product)}
                          className={classes.menuItem}
                        >
                          {product.name}
                        </MenuItem>
                      ))}
                    </div>
                  )}
                </div>
              </Drawer>
            </Grid>
          </Grid>
        </Hidden>
      </div>
      {!variant && (
        <Box mt={{ xs: 6, md: 2 }} mb={3} display="flex" alignItems="center">
          <Container maxWidth="md">
            <div
              maxWidth="md"
              className={classes.title}
              style={{ position: "relative" }}
            >
              <Typography
                variant="h1"
                className={classes.titleText}
                color="secondary"
              >
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </Typography>
              <Typography color="secondary" component="h2">
                {subtitle}
              </Typography>
            </div>
          </Container>
        </Box>
      )}
      <Hidden smUp implementation="css">
        <img className={classes.img} src={background_image} alt="background" />
      </Hidden>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  container: ({ backgroundImage, color, variant }) => ({
    height: "auto",
    overflow: "hidden",
    position: "relative",
    background: `linear-gradient(${color} 70%, transparent 40%)`,
    paddingTop: variant ? 120 : 80,
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      background: `no-repeat url('${backgroundImage}') right top/620px`,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80,
    },
  }),
  titleText: {
    fontSize: 55,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      marginBottom: theme.spacing(2),
    },
  },
  titleTextVariant: {
    fontSize: 36,
    fontWeight: 700,
    maxWidth: 700,
    ["@media(max-width: 1000px)"]: {
      maxWidth: 500,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      maxWidth: "unset",
      marginBottom: theme.spacing(2),
    },
  },
  svgContainer: ({ color }) => ({
    display: "none",
    color,
    height: "100%",
    backgroundColor: color,
    position: "absolute",
    top: 0,
    left: 0,
    width: "calc(100vw - 635px)",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  }),
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  iconVariant: {
    display: "flex",
    alignItems: "center",
    flexBasis: "auto",
    marginRight: theme.spacing(3),
    "& > p": {
      marginLeft: theme.spacing(1),
      color: theme.palette.secondary.main,
      fontSize: 15,
    },
  },
  svg: {
    position: "absolute",
    right: -130,
    height: "100%",
  },
  title: {
    lineHeight: "110%",
    marginBottom: 16,
    marginTop: 70,
    width: "40%",
    marginRight: "auto",
    position: "relative",
    marginBottom: 150,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      width: "100%",
      marginTop: 0,
    },
  },
  isSticky: {
    position: "fixed !important",
    margin: "auto",
    zIndex: 20,
    borderRadius: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    left: 0,
    top: 0,
    "& > div": {
      margin: "auto !important",
      marginTop: "0 !important",
      marginBottom: "0 !important",
    },
  },
  whiteBackground: {
    borderRadius: 50,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  desktopContainer: ({ variant }) => ({
    position: "relative",
    maxWidth: variant ? 900 : 744,
    marginLeft: variant ? "max(calc(50% - 604px) , 24px)" : "auto",
    marginRight: "auto",
    marginTop: variant ? theme.spacing(7) : 0,
    ["@media(max-width: 1000px)"]: {
      maxWidth: 600,
    },
  }),
  flex: {
    flex: 1,
  },
  mobileContainer: {
    padding: theme.spacing(1),
  },
  mobileProduct: {
    marginTop: theme.spacing(3),
    width: "100%",
    padding: theme.spacing(1),
    borderRadius: 50,
    backgroundColor: theme.palette.common.white,
    margin: "auto",
  },
  productButton: {
    paddingLeft: theme.spacing(2),
    fontFamily: ["GT Welsheim Pro", "Arial", "sans-serif"],
    borderRadius: 50,
    width: "100%",
    color: "#787878",
    display: "flex",
    justifyContent: "space-between",
  },
  productIconContainer: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.secondary.main,
    width: 36,
    height: 36,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
    borderRadius: "50%",
    "& > svg": {
      width: 14,
    },
  },
  img: {
    margin: "auto",
    padding: theme.spacing(2),
    display: "block",
    width: "100%",
  },
  drawer: {
    width: "100%",
    height: "100%",
    left: 0,
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  drawerItem: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3, 2),
  },
  placeholder: {
    color: "#787878",
    paddingLeft: theme.spacing(2),
  },
  menuItem: {
    height: 54,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  scrollable: {
    height: "calc(100vh - 79px)",
    overflowY: "auto",
    color: "#3E3E3E",
  },
}))
