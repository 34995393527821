import React, { useState } from "react"

import { withStyles } from "@material-ui/core/styles"
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"
import axios from "axios"

import {
  CircularProgress,
  Container,
  makeStyles,
  Typography,
  InputBase,
  Button,
  Checkbox,
  ButtonBase,
} from "@material-ui/core"

const CircularProgressStyled = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5,
    color: "#fff",
    marginLeft: 5,
  },
}))(CircularProgress)

export default function OptInBlock({
  optInImage,
  optInImageAlt,
  optInTitle,
  optInButton,
  optInText,
  optInTextRgpd,
  isPreview,
}) {
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const [email, setEmail] = useState("")
  const [optinGdprBrut, setOptinGdprBrut] = useState(false)

  function handleSubscribe() {
    setIsError(false)
    setIsLoading(true)
    let data = {
      email,
      optin_gdpr_brut: optinGdprBrut,
    }
    axios({
      method: "post",
      url: `${process.env.GATSBY_MURFY_API_URL}/murfy-app/subscribe/`,
      data: data,
    })
      .then(response => {
        setEmail("")
        setOptinGdprBrut(false)
        setIsLoading(false)
      })
      .catch(response => {
        setIsLoading(false)
        setIsError(true)
        console.error(response)
      })
  }

  function handleChangeOptinrgpg() {
    setOptinGdprBrut(prev => !prev)
  }

  return (
    <Container maxWidth="md" className={styles.container}>
      <div className={styles.blockSubcontainer}>
        <div className={styles.shadowContainerImage}>
          <div className={styles.subContainerImage}>
            <ImgWithLazyload
              isPreview={isPreview}
              className={styles.imageNoBackground}
              alt={optInImageAlt}
              src={optInImage}
            />
          </div>
        </div>
        <div className={styles.textContainer}>
          <Typography variant="h2" component="h3">
            {optInTitle}
          </Typography>
          <Typography className={styles.text} variant="body2">
            {optInText}
          </Typography>
          <div className={styles.signupWrapper}>
            <div className={styles.signupScroll}>
              <InputBase
                type="email"
                name="EMAIL"
                className={styles.email}
                id="mceEmail"
                placeholder="Votre adresse mail"
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
              <div className={styles.buttonContainer}>
                {isLoading ? (
                  <CircularProgressStyled className={styles.loader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    name="subscribe"
                    onClick={handleSubscribe}
                    className={styles.goButton}
                  >
                    {optInButton}
                  </Button>
                )}
              </div>
            </div>
            <div className={styles.checkcontainer}>
              <ButtonBase onClick={handleChangeOptinrgpg}>
                <Checkbox
                  className={styles.checkbox}
                  color="primary"
                  checked={optinGdprBrut ? "checked" : ""}
                />
              </ButtonBase>
              <Typography className={styles.consentSpan}>
                {optInTextRgpd}
              </Typography>
              {isError && <span>Une erreur est survenue</span>}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  goButton: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  text: {
    marginTop: theme.spacing(),
  },
  consentSpan: {
    fontSize: 13,
    marginTop: 13,
  },
  checkbox: {
    "& path": {
      fill: "white",
    },
  },
  checkcontainer: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(),
    },
  },
  container: {
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: "unset",
    },
  },
  blockContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
  },
  blockSubcontainer: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: 0,
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },

  shadowContainerImage: {
    display: "flex",
    width: "50%",
    objectFit: "cover",
    paddingTop: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      paddingTop: 20,
      order: 1,
    },
  },

  textContainer: {
    width: "50%",
    textAlign: "left",
    paddingTop: theme.spacing(10),
    marginLeft: theme.spacing(3),
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingTop: 40,
      margin: 0,
      "& .textContainerTitle": {
        fontSize: 26,
      },
    },
  },

  imageContainerNoBackground: {
    margin: 0,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 48,
    },
  },

  imageNoBackground: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },

  signupWrapper: {
    textAlign: "left",
    display: "flex",
    justifyContent: "left",
    flexDirection: "column",
    width: "70%",
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  optinCheckbox: {
    marginLeft: 0,
  },
  email: {
    backgroundColor: "white",
    borderRadius: 12,
    paddingLeft: theme.spacing(2),
    height: 60,
    width: "100%",
  },
  buttonContainer: {
    position: "absolute",
    top: theme.spacing(),
    right: theme.spacing(2),
  },
  signupScroll: {
    position: "relative",
  },
}))
