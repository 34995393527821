import React from "react"
import { makeStyles, Box, Typography, Container, Link } from "@material-ui/core"

import Slide from "./slide"
import Arrow from "./assets/arrow.svg"

const Slider = ({ slides, title, subtitle, href }) => {
  const classes = useStyle()
  return (
    <>
      <Container maxWidth="md">
        <Typography
          variant="h2"
          align="center"
          className={classes.title}
          color="secondary"
        >
          {title}
        </Typography>
        <Link
          href={href}
          variant="body"
          color="secondary"
          className={classes.subtitle}
        >
          {subtitle}
          <Arrow className={classes.arrow} />
        </Link>
      </Container>
      <div className={classes.block}>
        <Container maxWidth="md" className={classes.container}>
          <div className={classes.inner}>
            {slides.map(props => {
              return <Slide {...props} />
            })}
          </div>
        </Container>
      </div>
    </>
  )
}
export default Slider

const useStyle = makeStyles(theme => ({
  title: {
    maxWidth: 800,
    margin: "auto",
  },
  block: {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      marginLeft: 500,
      marginRight: 500,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&::-webkit-scrollbar-track": {
        marginLeft: 200,
        marginRight: 200,
      },
    },
    "&:hover": {
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f6f6f6",
        border: "1px solid #f6f6f6",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },

  inner: {
    display: "flex",
    position: "absolute",
    left: theme.spacing(2),
    top: 40,
    "& >:first-child": {
      marginLeft: 0,
    },
  },
  container: {
    position: "relative",
    height: 320,
  },
  arrow: {
    marginLeft: theme.spacing(1),
  },
  title__container: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(8),

    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
    },
  },
  subtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    color: theme.palette.text.turquoise,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0),
    },
  },
  slider__scroller: {
    overflowX: "auto",
    overflowY: "hidden",
    height: "100%",
    marginLeft: theme.spacing(20),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),

    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },

  slider__content: {
    [theme.breakpoints.up("lg")]: {
      width: "100%",
    },

    justifyContent: "center",
    display: "flex",
    paddingBottom: theme.spacing(3),
    height: "100%",

    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
}))
