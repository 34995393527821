import React from "react"
import {
  Container,
  makeStyles,
  Typography,
  Link,
  Button,
} from "@material-ui/core"

import ImgWithLazyload from "src/utils/components/ImageWithLazyload"
import useColor from "src/hooks/useColor"
import { repairStorage } from "../../helpers/repairStorage"
import { RoundedInput } from "components/roundedInput/RoundedInput"
import PlaceIcon from "@material-ui/icons/Place"
import { GoogleAutoComplete } from "components/googleAutoComplete/GoogleAutoComplete"

export default function USPBlock({
  isImageFirst,
  isTextFirst,
  image,
  title,
  text,
  USPType,
  buttonText,
  buttonLink,
  backgroundColor,
  textColor,
  textBackgroundColor,
  imageAlt,
  titleColor,
  isAdressInput,
  isPreview,
}) {
  const withTextBackground = USPType === "Text With Background"
  const textBackground = useColor(textBackgroundColor)

  const textColorComputed = useColor(textColor)
  const titleColorComputed = useColor(titleColor)
  const blocBackground = useColor(backgroundColor)
  const styles = useStyle({
    textColorComputed,
    withTextBackground,
    isImageFirst,
    textBackground: withTextBackground ? textBackground : "inherit",
    isTextFirst,
    titleColorComputed,
    blocBackground,
  })

  const handleChangeAddress = React.useCallback(address => {
    repairStorage.setAddress(address)
  }, [])
  return (
    <Container maxWidth="md">
      <div className={styles.container2}>
        <div className={styles.blockSubcontainer}>
          <ImgWithLazyload
            isPreview={isPreview}
            alt={imageAlt}
            className={styles.image1container}
            src={image}
          />
          <div className={styles.backTextContainer}>
            <div className={styles.textContainer}>
              <Typography variant="h2" className={styles.title}>
                {title}
              </Typography>
              <Typography>{text}</Typography>
              {buttonText && !withTextBackground && (
                <Link
                  className={styles.ctaButton}
                  href={buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="secondary"
                >
                  {buttonText}
                  <svg
                    className={styles.arrow}
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M14.0769 5.80119C14.1137 5.72817 14.1321 5.63691 14.1321 5.52739C14.1321 5.41787 14.1137 5.30227 14.0769 5.18059C14.04 5.14408 14.0124 5.10757 13.9939 5.07107C13.9755 5.03456 13.9479 4.99197 13.911 4.9433L9.19417 0.270549C9.03449 0.124523 8.85024 0.0515137 8.64142 0.0515137C8.4326 0.0515137 8.24835 0.124523 8.08866 0.270549C7.94126 0.42874 7.86756 0.611267 7.86756 0.818134C7.86756 1.025 7.94126 1.20753 8.08866 1.36572L11.4789 4.72426H0.792283C0.546613 4.72426 0.35315 4.79423 0.21189 4.93417C0.0706292 5.07411 0 5.25968 0 5.49089C0 5.73426 0.0706292 5.92591 0.21189 6.06585C0.35315 6.20579 0.546613 6.27576 0.792283 6.27576H11.4789L8.08866 9.61605C7.94126 9.77424 7.86756 9.95677 7.86756 10.1636C7.86756 10.3705 7.94126 10.553 8.08866 10.7112C8.17465 10.7964 8.26984 10.8572 8.37425 10.8938C8.47866 10.9303 8.56772 10.9485 8.64142 10.9485C8.7274 10.9485 8.8226 10.9303 8.92701 10.8938C9.03142 10.8572 9.12047 10.7964 9.19417 10.7112L13.911 6.03848C13.9479 6.00197 13.9847 5.96546 14.0216 5.92896C14.0584 5.89245 14.0769 5.84986 14.0769 5.80119Z"
                    />
                  </svg>
                </Link>
              )}
              {buttonText && !isAdressInput && withTextBackground && (
                <Button
                  variant="contained"
                  color="primary"
                  className={styles.ctaButton}
                  href={buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {buttonText}
                </Button>
              )}
              {isAdressInput && (
                <div className={styles.adressContainer}>
                  <GoogleAutoComplete
                    onChange={handleChangeAddress}
                    id="uspBlockGoogle"
                  >
                    <RoundedInput
                      fullWidth
                      backgroundColor="white"
                      placeholder="Saisissez votre adresse"
                      InputProps={{
                        endAdornment: (
                          <PlaceIcon style={{ color: "#A4E7DB" }} />
                        ),
                      }}
                    />
                  </GoogleAutoComplete>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.googleButton}
                    href={buttonLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {buttonText}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const useStyle = makeStyles(theme => ({
  googleButton: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
  textContainer: {
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "min-content min-content min-content",
    gridRowGap: theme.spacing(2),
    color: ({ textColorComputed }) => textColorComputed,
  },
  title: {
    color: ({ titleColorComputed }) => titleColorComputed,
    marginBottom: theme.spacing(),
  },
  adressContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
    alignItems: "center",
    "& :first-child": {
      marginRight: theme.spacing(),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  backTextContainer: {
    backgroundColor: ({ textBackground }) => textBackground,
    width: ({ withTextBackground }) => (withTextBackground ? "50%" : "40%"),
    padding: ({ withTextBackground }) =>
      withTextBackground ? "72px 80px" : "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      padding: ({ withTextBackground }) =>
        withTextBackground ? theme.spacing(3) : "unset",
      paddingTop: ({ withTextBackground }) =>
        withTextBackground ? theme.spacing(6) : "unset",
      paddingBottom: ({ withTextBackground }) =>
        withTextBackground ? theme.spacing(6) : "unset",
      width: ({ withTextBackground }) => "100%",
      marginTop: ({ withTextBackground }) =>
        withTextBackground ? 0 : theme.spacing(3),
      marginBottom: ({ withTextBackground }) =>
        withTextBackground ? 0 : theme.spacing(3),
    },
  },
  arrow: {
    fill: theme.palette.secondary.main,
    marginLeft: theme.spacing(),
  },
  container2: {
    padding: 32,
    backgroundColor: ({ blocBackground }) => blocBackground,
    [theme.breakpoints.down("sm")]: {
      padding: ({ blocBackground }) => (blocBackground !== "inherit" ? 32 : 0),
    },
  },
  ctaButton: {
    fontWeight: 500,
    fontSize: 17,
    marginTop: theme.spacing(3),
  },
  blockSubcontainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      margin: "auto",
    },
  },
  image1container: {
    margin: "auto 0",
    width: "51%",
    order: ({ isImageFirst }) => (isImageFirst ? 0 : 1),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      order: ({ isTextFirst }) => (isTextFirst ? 2 : 0),
    },
  },
}))
