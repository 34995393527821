import React from "react"
import showdown from "showdown"
import { makeStyles } from "@material-ui/core"

const converter = new showdown.Converter()

export default function BlueBanner({ content }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <span dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    color: theme.palette.secondary.main,
    display: "flex",
    backgroundColor: "#A4E7DB",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      margin: 0,
      padding: theme.spacing(1),
    },
    "& a": {
      color: theme.palette.secondary.main,
    },
  },
}))
