import React, { Component } from 'react'
import styles from "./monoOffer.module.scss"
import CheckIcon from '@material-ui/icons/Check'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import ZeroRiskPackageTooltip from './ZeroRiskPackageTooltip'
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"

export default class MonoOffer extends Component {

  constructor(props) {
    super(props)
    this.state = {
      indexes: [],
      isTooltipDialogOpen: false
    }
  }
  componentDidMount = () => {
    window.addEventListener('resize', this.update)
  }

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.update)
  }

  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    })
  }


  render() {
    const {
      monoOfferImage,
      monoOfferImageAlt,
      monoOfferRepairContent,
      monoOfferRepairButton,
      monoOfferRepairSubtitle,
      monoOfferRepairTooltipContent,
      monoOfferRepairTitle,
      isPreview } = this.props;

    return (
      <div className={styles.murfyPriceHomepageContainer}>
        <div className={styles.murfyLandingPageSubcontainer}>
          <div className={styles.murfyPriceHomepageContainerWrap}>
            <ImgWithLazyload isPreview={isPreview} alt={monoOfferImageAlt} className={styles.image} src={monoOfferImage} />
            <div className={styles.murfyPriceHomePageBox}>

              <h3 className={styles.murfyCtaTitle}>{monoOfferRepairTitle}</h3>
              <h4 className={styles.caption}>{monoOfferRepairSubtitle}</h4>
              <div className='murfy-offers_price-container'>
                <p className={styles.murfyCtaPrice}>85 €</p>
              </div>

              {monoOfferRepairContent && monoOfferRepairContent.map((content, index) => (
                <div className={` ${styles.small} ${styles.items} `} key={index}>
                  <CheckIcon className={styles.iconRepair} /> <p> {content.row}</p>
                </div>
              ))}

              <div className={` ${styles.small} ${styles.items} `}>
                <VerifiedUserIcon className={styles.iconRepair} /> <p> Pack "Zéro Risque" inclus &nbsp; <span className={styles.toolTipButton}> <ZeroRiskPackageTooltip TooltipContent={monoOfferRepairTooltipContent} /></span><br />
                  <span className={styles.murfyCtaDescriptionSubtext}>Récupérez vos 85€ en avoirs si les pièces détachées sont trop chères</span>
                </p>
              </div>
              <div id='demander-une-intervention' className={styles.murfyCtaCallToActionContainer}>
                <a className={` ${styles.murfyPriceButton} ${styles.repairButton} `} href="/rendez-vous-reparateur/choix-de-creneau">{monoOfferRepairButton}</a>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}