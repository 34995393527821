import React from "react"
import { makeStyles, Typography } from "@material-ui/core"

import Arrow from "./assets/arrow.svg"

const FaqCard = ({ title, description, picto, link, linkText }) => {
  const classes = useStyle({ link })
  const Wrapper = link ? "a" : "div"
  return (
    <Wrapper href={link} className={classes.card__container}>
      <div className={classes.picto}>
        <img src={picto} alt={title} />
      </div>
      <div className={classes.title__container}>
        <Typography variant="body1" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.text} variant="body2">
          {description}
        </Typography>
        {linkText && linkText !== "" && (
          <Typography className={classes.link} variant="body2">
            {linkText}
            <Arrow className={classes.arrowIcon} />
          </Typography>
        )}
      </div>
    </Wrapper>
  )
}

export default FaqCard

const useStyle = makeStyles(theme => ({
  arrowIcon: {
    marginLeft: theme.spacing(),
  },
  text: {
    lineHeight: "22px",
  },
  link: {
    marginTop: theme.spacing(2),
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  card__container: {
    display: "flex",
    margin: `0px ${theme.spacing(2)}px`,
    position: "relative",
    flexDirection: "column",
    width: "310px",
    cursor: "default",
    backgroundColor: "white",
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
    [theme.breakpoints.down("sm")]: {
      margin: `0px ${theme.spacing(1)}px`,
    },
  },
  picto: {
    display: "flex",
    justifyContent: "flex-start",
  },
  title__container: {
    color: theme.palette.secondary.dark,
    marginTop: theme.spacing(2),
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      width: "250px",
    },
  },
  title: {
    fontWeight: "bold",
    lineHeight: "26px",
    marginBottom: theme.spacing(),
  },
}))
